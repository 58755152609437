import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.scss';
import './index.scss';
import App from './App';
import TaskStore from './stores/task.store';
import { Provider } from 'mobx-react';

import * as serviceWorker from './serviceWorker';
import { IUser, AuthProvider } from './components/Auth';

const taskStore = new TaskStore(window.USER);
taskStore.fetchTask();
window.store = taskStore;
//setTimeout(() => taskStore.fetchTask(), 1000)

const root = (
  <Provider taskStore={taskStore}>
    <AuthProvider endpoint={window.USER_ENDPOINT} user={null}>
      <App />
    </AuthProvider>
  </Provider>
);

ReactDOM.render(root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


declare global {
  interface Window {
    store: TaskStore;
    location: Location;
    BASEPATH: string;
    USER_ENDPOINT: string;
    BACKEND_ENDPOINT: string;
    USER: IUser;
  }
}