import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

// Routes Management
import RouterWrapper from './containers/RouterWrapper';

// Locales
import itLocaleData from 'react-intl/locale-data/it';
import enLocaleData from 'react-intl/locale-data/en';

// Translations sheets
import itMessages from './translations/it.json';
import enMessages from './translations/en.json';

// Internationalization
addLocaleData(itLocaleData);
addLocaleData(enLocaleData);

const messages: any = {
  en: enMessages,
  it: itMessages
};

// take from browser
let language = navigator.language.toLowerCase().split(/[_-]+/)[0];

// Force Italian
language = 'it';

if (!messages[language]) {
  language = Object.keys(messages)[0];
}

class App extends Component<any, any> {
  state = {
    locale: language
  };

  componentDidMount() {
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });

    document.addEventListener('gesturechange', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });

    document.addEventListener('gestureend', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });
  }

  componentWillUnmount() {
    document.removeEventListener('gesturestart', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });

    document.removeEventListener('gesturechange', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });

    document.removeEventListener('gestureend', function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = '1';
    });
  }

  onLanguageChanged = (lang: string) => {
    this.setState({
      locale: lang
    })
  };

  render() {
    const { locale } = this.state;

    return (
      <IntlProvider
        defaultLocale="it"
        locale={locale}
        messages={messages[locale] || messages['it']}>
        <BrowserRouter basename={window.BASEPATH}>
          <RouterWrapper
            localeChanged={this.onLanguageChanged}
            locale={locale} />
        </BrowserRouter>
      </IntlProvider >
    );
  }
}

export default App;
