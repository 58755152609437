import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import classes from './styles.module.scss';
import { inject, observer } from 'mobx-react';
import ReactPlayer from "react-player";
import { ITask, ITaskStore } from '../../shared/interfaces';
import playIcon from '../../assets/icons/play.svg';
import { TRAILER } from '../../shared/data';

interface IProps {
  setVisibility: () => void;
  show: boolean;
  taskStore: ITaskStore;
  locale: string;
  title?: object;
  cta?: object;
  isTrailer?: boolean;
}

interface IState {
  playing: boolean;
  task: ITask | undefined;
}

@inject('taskStore')
@observer
class TrailerModal extends Component<IProps, IState> {
  modal = createRef<HTMLDivElement>();

  state = {
    playing: false,
    task: this.props.isTrailer ? TRAILER : this.props.taskStore.tasks.find((item: ITask) => item.id === 0)
  };

  componentDidMount(): void {
    document.addEventListener('click', this.handleClickedOutsideSelectorModal, false);
  }

  handleClickedOutsideSelectorModal = (e: any) => {
    if (this.modal.current && !this.modal.current.contains(e.target) && this.props.show) {
      this.props.setVisibility();
    }
  };

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickedOutsideSelectorModal, false);
  }

  handlePlayingStoppingVideo = () => {
    let playing = !this.state.playing;

    this.setState(
      {
        playing
      }
    )
  };

  render() {
    const { show, setVisibility, locale, title, cta } = this.props;
    const { playing, task } = this.state;

    if(!task) {
      return null;
    }

    return (
      <div
        className={[classes.TrailerModal, show ? classes['is-visible'] : ''].join(' ')}>
        <div
          ref={this.modal}
          className={[
            classes['TrailerModal-container'],
            show ? classes['is-visible'] : ''
          ].join(' ')}>
          <div className={classes['TrailerModal-header']}>
            <div className={classes['TrailerModal-header']}>
              <h2 className={classes['TrailerModal-title']}>
                {
                  title || <FormattedMessage id={'intro.title'} />
                }
              </h2>
            </div>
          </div>
          <div className={classes['TrailerModal-content']}>
            <ReactPlayer
              className={[classes['TrailerModal-player'], playing ? classes['TrailerModal-player--over'] : ''].join(' ')}
              url={task.content[locale || 'it'][0].url}
              width={'100%'}
              height={'100%'}
              config={{
                vimeo: {
                  playerOptions: { controls: true }
                },
              }}
              playing={playing} />
              <div className={[classes['TrailerModal-poster'], !playing ? classes['is-visible'] : ''].join(' ')}>
                <div
                  className={[classes['TrailerModal-background'], !playing ? classes['is-visible'] : ''].join(' ')}
                  style={{ backgroundImage: `url(${task.content[locale || 'it'][0].poster})`}} />
                <div className={classes['TrailerModal-controls']}>
                  <Button
                    icon={playIcon}
                    size={'small'}
                    clicked={() => this.handlePlayingStoppingVideo()} />
                </div>
              </div>
          </div>
          <div className={classes['TrailerModal-close']}>
            <Button
              clicked={setVisibility}
              styles={{width: '12rem'}}
              size={'small'}>
              {
                cta || <FormattedMessage id={'button.popup.next'} />
              }
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

export default TrailerModal;
