import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
	currentLanguage: string;
	languageChanged: (lang: string) => void;
	styles?: object;
	size?: string;
}

class LanguageSwitcher extends Component<IProps> {
	render () {
		const { languageChanged, currentLanguage, styles, size } = this.props;
		return (
			<div className={[classes['LanguageSwitcher'], size ? classes[`LanguageSwitcher--${size}`] : ''].join(' ')} style={styles}>
				<button className={[
					classes['LanguageSwitcher-button'],
					currentLanguage === 'it' ? classes['is-active'] : ''
				].join(' ')} onClick={() => languageChanged('it')}>ITA</button>
				<button className={[
					classes['LanguageSwitcher-button'],
					currentLanguage === 'en' ? classes['is-active'] : ''
				].join(' ')} onClick={() => languageChanged('en')}>ENG</button>
			</div>
		);
	}
}

export default LanguageSwitcher;
