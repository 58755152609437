import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { getViewPortSize } from '../../shared/helpers';
import classes from './styles.module.scss';

import doneIcon from '../../assets/icons/done.svg';

interface IProps {
	children?: object | string;
	clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	styles?: object;
	disabled?: boolean;
	redirectTo?: string;
	icon?: string;
	theme?: 'success';
	active?: boolean;
  size?: 'small' | 'medium' | 'large';
  circle?: boolean;
  flat?: boolean;
  mouseOut?: () => void;
  mouseOver?: () => void;
}

class Button extends Component<IProps, { showMouseEvents: boolean | null }> {
  state = {
    showMouseEvents: null,
  };

  componentDidMount(): void {
    const size = getViewPortSize();
    if (size && size.width) {
      this.setState({
        showMouseEvents: size.width >= 1200
      })
    }
  }

  render() {
    const { children, clicked, styles, disabled, theme, icon, redirectTo, size, active, circle, flat, mouseOut, mouseOver } = this.props;

    const buttonActionClasses = [
      classes['Button-action'],
      theme ? classes[`Button-action--${theme}`] : '',
      size ? classes[`Button-action--${size}`] : '',
    ].join(' ');

    const content = (
      <Fragment>
        <span className={[classes['Button-label']].join(' ')}>{ children }</span>
        {
          icon &&
            <img src={icon} alt={'icon'} />
        }
      </Fragment>
    );

    const redirect = redirectTo ?
      <NavLink
        className={buttonActionClasses}
        to={redirectTo}>
        { content }
      </NavLink> :
      <button
        type={'button'}
        disabled={disabled}
        style={styles}
        onClick={clicked}
        onMouseOut={this.state.showMouseEvents ? mouseOut : undefined}
        onMouseOver={this.state.showMouseEvents ? mouseOver : undefined}
        className={buttonActionClasses}>
        { content }
      </button>;

    let buttonClasses = [
      classes.Button,
      active ? classes['is-active'] : '',
      disabled ? classes['is-disabled'] : '',
      icon && !children ? classes['Button--icon'] : '',
      circle ? classes['Button--circle'] : '',
      flat ? classes['Button--flat'] : '',
    ];

    return (
      <div
        style={styles}
        className={buttonClasses.join(' ')}>
        { redirect }
        {
          active &&
            <img src={doneIcon} alt={'done'} />
        }
      </div>
    );
  }
}

export default Button;
