import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import classes from './styles.module.scss';

import introMarkersImage from '../../assets/images/intro-markers.png';
import introNavigationImage from '../../assets/images/intro-navigation.png';

import Scroll from 'react-scroll';
const Element = Scroll.Element;
const Scroller = Scroll.scroller;

interface IProps {
  setVisibility: () => void;
  show: boolean;
}

interface IState {
  showContainer: boolean;
}

class IntroModal extends Component<IProps, IState> {
  private selectorModal!: HTMLDivElement;
  state = {
    showContainer: false,
  };

  handleClickedOutsideSelectorModal = (e: any) => {
    if (this.selectorModal && !this.selectorModal.contains(e.target) && this.props.show) {
      this.props.setVisibility();
    }
  };

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickedOutsideSelectorModal, false);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {

    if(this.props.show !== prevProps.show){
      //console.log(this.props.show);
      if(this.props.show) {
        Scroller.scrollTo('IntroModal', {
          duration: 300,
          delay: 0,
          smooth: true,
          containerId: 'ModalScroller',
        });

        this.setState({
          showContainer: true,
        }, () => {
          document.addEventListener('click', this.handleClickedOutsideSelectorModal, false);
        });
      }
      else {
        document.removeEventListener('click', this.handleClickedOutsideSelectorModal, false);

        setTimeout(() => {
          this.setState({
            showContainer: false
          })
        }, 500);
      }
    }
  }

  render() {
    const { show, setVisibility } = this.props;

    const { showContainer } = this.state;

    return (
      <div
        id={"ModalScroller"}
        className={[classes.IntroModal, showContainer ? classes['is-visible'] : ''].join(' ')}>
        <Element name={'IntroModal'} />
        <div
          ref={(node: HTMLDivElement) => { this.selectorModal = node; }}
          className={[
            classes['IntroModal-container'],
            show ? classes['is-visible'] : ''
          ].join(' ')}>
          <div className={classes['IntroModal-header']}>
            <div className={classes['IntroModal-header']}>
              <h2 className={classes['IntroModal-title']}>
                <FormattedMessage id={'intro.title'} />
              </h2>
              <h3 className={classes['IntroModal-subtitle']}>
                <FormattedMessage id={'intro.question'} />
              </h3>
            </div>
          </div>
          <div className={classes['IntroModal-content']}>
            <div className={classes['IntroModal-box']}>
              <img src={introMarkersImage} alt={'markers'} />
              <FormattedMessage id={'intro.markers'} />
            </div>
            <div className={classes['IntroModal-separator']}>
              <FormattedMessage id={'label.or'} />
            </div>
            <div className={classes['IntroModal-box']}>
              <img src={introNavigationImage} alt={'navigation'} />
              <FormattedMessage id={'intro.navigation'} />
            </div>
          </div>
          <div className={classes['IntroModal-close']}>
            <Button
              clicked={setVisibility}
              styles={{width: '12rem'}}
              size={'small'}>
              <FormattedMessage id={'button.popup.start'} />
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

export default IntroModal;
