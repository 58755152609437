import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ITaskStore } from '../../shared/interfaces';
import { inject, observer } from 'mobx-react';
import { History } from 'history';
import Button from '../Button';
import Logo from '../Logo';
import classes from './styles.module.scss';
import { MAP } from '../../shared/routes';

// Placeholder
import introBackground from '../../assets/images/intro.png';

// Icon
import arrowStartIcon from '../../assets/icons/arrow_start.svg';
import LanguageSwitcher from '../LanguageSwitcher';

interface IProps {
  taskStore: ITaskStore;
  localeChanged: (lang: string) => void;
  locale: string;
  history: History;
}

@inject('taskStore')
@observer
export default class Welcome extends Component<IProps> {
  onStartClicked = () => {
    this.props.taskStore.registerEvent('start');
    this.props.history.push(MAP);
  };

  render() {
    const { locale, localeChanged } = this.props;

    return (
      <div className={classes.Welcome}>
        <Logo />
        <LanguageSwitcher
          currentLanguage={locale}
          languageChanged={localeChanged} />
        <div
          className={classes['Welcome-wrapper']}>
          <div className={classes['Welcome-start']}>
            <h1 className={classes['Welcome-title']}>
              <FormattedMessage id={'welcome.text'} />
            </h1>
            <Button
              icon={arrowStartIcon}
              clicked={this.onStartClicked}>
              <FormattedMessage id={'button.start'} />
            </Button>
          </div>
          <img className={classes['Welcome-background']} src={introBackground} alt={'intro'} />
        </div>
      </div>
    );
  }
}
