import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import TaskSelector from '../TaskSelector';
import classes from './styles.module.scss';

import Scroll from 'react-scroll';
import { ITaskStore } from '../../shared/interfaces';
const Element = Scroll.Element;
const Scroller = Scroll.scroller;

interface IProps {
  taskStore: ITaskStore;
  taskClicked: (id: number) => void;
  setVisibility: () => void;
  showTrailerClicked: () => void;
  show: boolean;
  id: number;
}

interface IState {
  showContainer: boolean;
}

class TaskSelectorModal extends Component<IProps, IState> {
  private selectorModal!: HTMLDivElement;
  state = {
    showContainer: false,
  };

  handleClickedOutsideSelectorModal = (e: any) => {
    if (this.selectorModal && !this.selectorModal.contains(e.target) && this.props.show) {
      this.props.setVisibility();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickedOutsideSelectorModal, false);
  }

  handleGoToMarker = (id: number) => {
    this.props.setVisibility();
    setTimeout(() => {
      this.props.taskClicked(id);
    }, 800)
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.id !== prevProps.id) {
      this.setState({
        showContainer: false,
      })
    }

    if (this.props.show !== prevProps.show) {
      //console.log(this.props.show);
      if (this.props.show) {
        Scroller.scrollTo('TaskSelectorModal', {
          duration: 300,
          delay: 0,
          smooth: true,
          containerId: 'ModalScroller',
        });

        this.setState({
          showContainer: true,
        }, () => {
          document.addEventListener('click', this.handleClickedOutsideSelectorModal, false);
        });
      }
      else {
        document.removeEventListener('click', this.handleClickedOutsideSelectorModal, false);

        setTimeout(() => {
          this.setState({
            showContainer: false
          })
        }, 500);
      }
    }
  }

  render() {
    const { show, setVisibility, showTrailerClicked } = this.props;

    const { showContainer } = this.state;

    return (
      <div
        id={"ModalScroller"}
        className={[classes.TaskSelectorModal, showContainer ? classes['is-visible'] : ''].join(' ')}>
        <Element name={'TaskSelectorModal'} />
        <div
          ref={(node: HTMLDivElement) => { this.selectorModal = node; }}
          className={[
            classes['TaskSelectorModal-container'],
            show ? classes['is-visible'] : ''
          ].join(' ')}>
          <div className={'TaskSelectorModal-content'}>
            {
              <TaskSelector
                taskStore={this.props.taskStore}
                trailerClicked={showTrailerClicked}
                taskClicked={(id) => this.handleGoToMarker(id)} />
            }
          </div>
          <div className={classes['TaskSelectorModal-close']}>
            <Button
              clicked={setVisibility}
              styles={{ width: '12rem' }}
              size={'small'}>
              <FormattedMessage id={'button.close'} />
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

export default TaskSelectorModal;
