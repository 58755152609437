import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IDocument } from '../../shared/interfaces';
import classes from './styles.module.scss';

interface IProps {
  docs?: Array<IDocument>;
  title?: object | string;
  onDocClick: any;
}

interface IState { }
class DocsList extends React.Component<IProps, IState> {



  render() {
    const { docs, title } = this.props;

    const docsTpl = docs ? docs.map((doc: IDocument, index: number) => {
      return (
        <li className={classes['DocsList-item']} key={index}>
          <div className={classes['DocsList-wrapper']}>
            <strong>{doc.title}</strong>
            <span>{doc.subtitle}</span>
          </div>
          <a href={doc.url} target={'_blank'} download onClick={() => this.props.onDocClick(doc.title)}>
            <FormattedMessage id={'button.download'} />
          </a>
        </li>
      )
    }) : null;


    return (
      <div
        className={classes.DocsList}>
        {
          title &&
          <h4 className={classes['DocsList-title']}>
            {title}
          </h4>
        }
        <ul className={classes['DocsList-items']}>
          {docsTpl}
        </ul>
      </div>
    );
  };
}

export default DocsList;
