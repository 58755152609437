import { get, isEqual, isUndefined } from "lodash";
import React, { Component } from "react";
import UserClient from "./client";

export interface IUser {
  id: string;
  displayName: string;
  scopes: IUserScopes;
  isAdmin: boolean;
}

interface IUserScopes {
  [propName: string]: any;
}

interface AuthContextProps {
  user: IUser | null;
}

export const AuthContext = React.createContext<AuthContextProps>({
  user: null
});

/**
 * Provider
 */
interface AuthProviderProps {
  endpoint: string;
  user: IUser | null;
}

interface AuthProviderState {
  user: IUser | null;
}

export class AuthProvider extends Component<
  AuthProviderProps,
  AuthProviderState
  > {
  private client: UserClient;
  private interval: any;

  constructor(props: AuthProviderProps) {
    super(props);
    this.client = new UserClient({ endpoint: props.endpoint });
    this.state = { user: props.user };
  }

  componentDidMount() {
    this.fetch();
    this.interval = setInterval(() => {
      this.fetch();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async fetch() {
    const info = await this.client.getUser();
    if (info && !isEqual(info.user, this.state.user)) {
      this.setState(() => ({
        user: info.user ? info.user.profile : null
      }));
    }
  }

  getContext() {
    return {
      user: this.state.user
    };
  }

  render() {
    return (
      <AuthContext.Provider value={this.getContext()}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

/**
 * Gate
 */
interface AuthGateProps {
  scope?: string;
  value?: any;
  children: (props: ChildProps) => React.ReactNode;
}

interface ChildProps {
  user: IUser | null;
  isAuthenticated: boolean;
  isAuthorized: boolean;
  scope: any;
}

export class AuthGate extends Component<AuthGateProps> {
  render() {
    return (
      <AuthContext.Consumer>
        {({ user }) => {
          let isAuthenticated = !!user;
          let isAuthorized = false;
          let scope = {};

          if (user) {
            if (this.props.scope) {
              scope = get(user.scopes, this.props.scope);
              if (isUndefined(scope)) {
                isAuthorized = false;
              } else if (!isUndefined(this.props.value)) {
                if (!isEqual(scope, this.props.value)) {
                  isAuthorized = false;
                } else {
                  isAuthorized = true;
                }
              } else {
                isAuthorized = true;
              }
            } else {
              isAuthorized = true;
            }
          } else {
            isAuthorized = false;
          }

          return this.props.children({
            user,
            isAuthenticated,
            isAuthorized,
            scope
          });
        }}
      </AuthContext.Consumer>
    );
  }
}
