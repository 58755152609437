import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import Logo from '../Logo';
import classes from './styles.module.scss';

// Placeholder
import introBackground from '../../assets/images/intro.png';

// Icon
import arrowStartIcon from '../../assets/icons/arrow_start.svg';
import LanguageSwitcher from '../LanguageSwitcher';

interface IProps {
  localeChanged: (lang: string) => void;
  locale: string;
}

export default class Login extends Component<IProps> {
  handleLoginClicked = (ev) => {
    window.location.href = `${process.env.NODE_ENV === 'production' ? `${window.BASEPATH}saml/login` : `${window.BASEPATH}/local/login`}`
    ev.preventDefault();

  };

  render() {
    const { locale, localeChanged } = this.props;

    return (
      <div className={classes.Login}>
        <Logo />
        <LanguageSwitcher
          currentLanguage={locale}
          languageChanged={localeChanged} />
        <div
          className={classes['Login-wrapper']}>
          <div className={classes['Login-start']}>
            <h1 className={classes['Login-title']}>
              <FormattedMessage id={'login.text'} />
            </h1>

            <Button
              icon={arrowStartIcon}
              clicked={this.handleLoginClicked}>
              <FormattedMessage id={'button.login'} />
            </Button>
          </div>
          <img className={classes['Login-background']} src={introBackground} alt={'intro'} />
        </div>
      </div>
    );
  }
}
