
interface UserClientConfig {
  endpoint: string
  authToken?: string
}

export default class UserClient {

  private endpoint: string
  private authToken?: string

  constructor(opts: UserClientConfig) {
    this.endpoint = opts.endpoint;
    this.authToken = opts.authToken;
  }

  async getUser() {
    if (this.endpoint) {
      const res = await fetch(`${this.endpoint}`, {
        credentials: 'include'
      });
      if (res.ok) {
        const info = await res.json();
        return info
      } else {
        throw new Error(`Error fetching user information`);
      }
    } else {
      console.error(`Endpoint is not setted: ${this.endpoint}`)
    }
  }
}
