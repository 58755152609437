import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import * as routes from '../../shared/routes';
// PrivateRoute
import PrivateRoute from '../../hocs/PrivateRoute';
// Routes
import Welcome from '../../components/Welcome';
import Map from '../../components/Map';
import ThankYou from '../../components/ThankYou';
import { AuthGate } from '../../components/Auth';
import Login from '../../components/Login';

interface IProps {
  locale: string;
  localeChanged: (lang: string) => void;
}

class RouterWrapper extends Component<IProps> {

  render() {
    const { locale, localeChanged } = this.props;

    return (
      <AuthGate>
        {({ user }) =>
          user || process.env.REACT_APP_TEST === 'true' ? (
            <Switch>
              <PrivateRoute
                exact={true}
                isAuthenticated={true}
                locale={locale}
                localeChanged={localeChanged}
                path={routes.WELCOME}
                component={Welcome} />
              <PrivateRoute
                exact={true}
                isAuthenticated={true}
                locale={locale}
                localeChanged={localeChanged}
                path={routes.MAP}
                component={Map} />
              <PrivateRoute
                exact={true}
                locale={locale}
                isAuthenticated={true}
                path={routes.THANK_YOU}
                localeChanged={localeChanged}
                component={ThankYou} />
              <Redirect to={routes.WELCOME} />
            </Switch>
          ) :
            <Login
              locale={locale}
              localeChanged={localeChanged}
            />
        }
      </AuthGate>
    );
  }
}

export default RouterWrapper;
