import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import * as routes from '../../shared/routes';
import { History } from 'history';
import { FormattedHTMLMessage } from 'react-intl';
import { getViewPortSize } from '../../shared/helpers';
import IntroModal from '../IntroModal';
import LanguageSwitcher from '../LanguageSwitcher';
import classes from './styles.module.scss';
import { IMapComponentState, IMarker, IMarkerPositions, IStyle, ITask, ITaskStore } from '../../shared/interfaces';
import MapMenuButton from '../MapMenuButton';
import Modal from '../Modal';
import TaskComponent from '../TaskComponent';
import TaskSelectorModal from '../TaskSelectorModal';
import TrailerModal from '../TrailerModal';
import Logo from '../Logo';
import Toast from '../Toast';
import Marker from '../Marker';
import { MARKER_POSITIONS } from '../../shared/data';
import isometricMapImage from '../../assets/images/isometric-map.jpg';
import AdminButton from '../AdminButton';

interface IProps {
  taskStore: ITaskStore;
  history: History;
  locale: string;
  localeChanged: (lang: string) => void;
}

@inject('taskStore')
@observer
class Map extends Component<IProps, IMapComponentState> {
  private wrapper = createRef<any>();

  state = {
    styles: {
      transformOrigin: '0% 0%',
      background: `url(${isometricMapImage}) transparent no-repeat center`
    },
    zoom: false,
    currentMarker: 0,
    showModal: false,
    viewPort: {
      height: window.innerHeight,
      width: window.innerWidth
    },
    showToast: false,
    setCompleted: 0,
    showMenu: false,
    markersTpl: undefined,
    scrollLeftPosition: 0,
    showIntro: false,
    showTrailerModal: false,
    introAlreadyWatched: false,
  };

  componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);

    setTimeout(() => {
      this.setState({
        showTrailerModal: true,
      })
    }, 300)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IMapComponentState>, snapshot?: any): void {
    if (this.state.zoom !== prevState.zoom && !this.state.zoom) {
      if (this.props.taskStore.tasksCompleted === 10) {
        setTimeout(() => {
          this.props.history.push(routes.THANK_YOU)
        }, 1200)
      }
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleToggleMenuModalVisibility = () => {
    const showMenu = !this.state.showMenu;

    this.setState({
      showMenu,
    })

    showMenu && this.props.taskStore.registerEvent('open-menu');
  };

  handleToggleModalVisibility = () => {
    const showModal = !this.state.showModal;

    if (!showModal) {
      this.handleResetZoom();
    }
  };

  updateDimensions = () => {
    const size = getViewPortSize();
    if (size && size.height && size.width) {
      const viewPort = {
        height: size.height,
        width: size.width
      };
      this.setState({ viewPort }, /* () => {
        this.setState({
          markersTpl: this.getMarkers()
        })
      } */);
    }
  };

  handleRunZoom = (offsetX: number, offsetY: number, currentMarker?: number) => {
    const { offsetWidth, offsetHeight } = this.wrapper.current;
    /// calculate deltas
    const deltaX = (100 / offsetWidth) * offsetX;
    const deltaY = (100 / offsetHeight) * offsetY;
    /// apply the style property to the image
    let styles = { ...this.state.styles };
    styles.transformOrigin = `${deltaX}% ${deltaY}%`;

    this.goToMarkerEffect(styles, currentMarker, true);
  };

  onMarkerSelected = (id: number) => {
    const currentMarker = id;

    const markerPositions = MARKER_POSITIONS.filter((mp: IMarkerPositions) => this.state.viewPort.width >= mp.minWidth);

    if (markerPositions.length) {
      const markerPosition = markerPositions[markerPositions.length - 1].markers.find((marker: IMarker) => marker.id === +currentMarker);

      if (markerPosition) {
        this.handleRunZoom(+markerPosition.x, +markerPosition.y, +currentMarker);
      }
    }
    this.props.taskStore.registerEvent('open-chapter', { chapter: id });
  };

  goToMarkerEffect = (styles: IStyle, markerId: number | undefined, zoom: boolean) => {
    this.setState({
      styles,
      currentMarker: markerId || this.state.currentMarker,
      zoom,
      showMenu: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          showModal: true,
        })
      }, 700);
    });
  };

  handleResetZoom = () => {
    this.setState({
      showModal: false,
    });

    setTimeout(() => {
      this.setState({
        currentMarker: 0,
        zoom: false,
        showToast: this.state.setCompleted > 0
      });
    }, 800);

    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, 2000);

    setTimeout(() => {
      this.setState({
        setCompleted: 0,
      });
    }, 3000);
  };

  handleCompleteTask = async (id: number) => {
    this.props.taskStore.setTaskCompleted(id);
    await this.props.taskStore.saveUserProgress();
    this.setState({
      setCompleted: id,
      /* markersTpl: this.getMarkers(true) */
    });

    this.handleToggleModalVisibility();
    this.props.taskStore.registerEvent('completed-chapter', { chapter: id });
  };

  getMarkers = (enabled: boolean = false) => {
    const { viewPort: { width }, showMenu, showModal } = this.state;
    const { taskStore } = this.props;

    const MARKERS = MARKER_POSITIONS.filter((mp: IMarkerPositions) => width >= mp.minWidth);

    return (
      MARKERS &&
      MARKERS[MARKERS.length - 1] &&
      MARKERS[MARKERS.length - 1].markers.map((mark: IMarker, index: number) => {
        const { src, activeSrc } = mark;
        const currentTask = taskStore.tasks.find((task: ITask) => task.id === mark.id);
        let disabled = !enabled || false;
        if (!enabled) {
          disabled = showModal || showMenu;
        }
        return (
          currentTask ?
            <Marker
              key={index}
              id={currentTask.id}
              clicked={() => this.onMarkerSelected(currentTask.id)}
              disabled={disabled}
              image={currentTask.completed ? activeSrc : src} />
            : null
        )
      })
    )
  };

  onCloseTrailerModal = (showIntro: boolean = false) => {
    this.setState({
      showTrailerModal: false,
    }, () => {
      if (showIntro) {
        setTimeout(() => {
          this.setState({
            showIntro: true,
          })
        }, 600)
      }
    })
  };

  showTrailerClicked = () => {
    this.setState({
      zoom: false,
      showMenu: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          showTrailerModal: true,
        })
      }, 500);
    });
  };

  render() {
    const { styles, zoom, currentMarker, showModal, showToast, showIntro, setCompleted, showMenu, showTrailerModal, introAlreadyWatched } = this.state;
    const markersTpl = this.getMarkers(true);
    const { taskStore, locale, localeChanged } = this.props;
    const selectedTask = taskStore.tasks.find((task: ITask) => task.id === +currentMarker);
    if (typeof window !== 'undefined') {
      showModal || showMenu || showIntro || showTrailerModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    }

    return (
      <div className={[classes.Map, zoom ? classes['Map--withZoom'] : ''].join(' ')}>
        <Logo />
        <LanguageSwitcher
          currentLanguage={locale}
          languageChanged={localeChanged} />
        <div className={[classes['Map-mobileClose'], zoom ? classes['is-visible'] : ''].join(' ')}>
          <MapMenuButton
            status={showModal ? 'open' : 'close'}
            clicked={this.handleToggleModalVisibility} />
        </div>
        <div className={classes['Map-AdminButtonContainer']}>
          <AdminButton />
        </div>
        <div
          id={"MapWrapper"}
          style={{ transformOrigin: styles.transformOrigin }}
          className={[classes['Map-wrapper'], zoom ? classes['is-highlighted'] : ''].join(' ')}
          ref={this.wrapper}>
          {
            markersTpl
          }
          <img className={classes['Map-background']} alt={'background-map'} src={isometricMapImage} />
          <div className={classes['Map-offset']} />
        </div>
        <div className={[classes['Map-toast'], showToast ? classes['is-visible'] : ''].join(' ')}>
          <Toast>
            <FormattedHTMLMessage id={'label.module'} /> {setCompleted}: <strong><FormattedHTMLMessage id={'label.completed'} /></strong>
          </Toast>
        </div>
        <Modal
          visible={showModal}
          onModalCloseClicked={this.handleToggleModalVisibility}>
          {
            currentMarker > 0 && selectedTask &&
            <TaskComponent
              locale={locale}
              completeTaskClicked={async () => await this.handleCompleteTask(currentMarker)}
              task={selectedTask}
            />
          }
        </Modal>
        {
          showTrailerModal &&
          <TrailerModal
            locale={this.props.locale}
            taskStore={this.props.taskStore}
            setVisibility={() => this.onCloseTrailerModal(!introAlreadyWatched)}
            isTrailer={introAlreadyWatched}
            title={introAlreadyWatched ? <FormattedHTMLMessage id={'intro.trailer'} /> : <FormattedHTMLMessage id={'intro.title'} />}
            cta={introAlreadyWatched ? <FormattedHTMLMessage id={'button.close'} /> : <FormattedHTMLMessage id={'button.popup.next'} />}
            show={showTrailerModal} />
        }
        <IntroModal
          setVisibility={() => this.setState({ showIntro: false, introAlreadyWatched: true })}
          show={showIntro} />
        <TaskSelectorModal
          taskStore={this.props.taskStore}
          show={showMenu}
          id={currentMarker}
          setVisibility={this.handleToggleMenuModalVisibility}
          showTrailerClicked={this.showTrailerClicked}
          taskClicked={(id: number) => this.onMarkerSelected(id)} />
        <MapMenuButton
          disabled={showModal}
          status={showMenu || showModal ? 'open' : 'close'}
          clicked={this.handleToggleMenuModalVisibility}
        />
      </div>
    );
  }
}

export default Map;
