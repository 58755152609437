import React, { Component } from 'react';
import closeIcon from '../../assets/icons/close.svg';
import menuIcon from '../../assets/icons/menu.svg';
import classes from './styles.module.scss';
import Button from '../Button';

interface IProps {
	clicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  status?: 'close' | 'open';
  disabled?: boolean;
}

class MapMenuButton extends Component<IProps> {
  render() {
    const { clicked, disabled, status } = this.props;

    const icon = status === 'open' ? closeIcon : menuIcon;

    return (
      <div className={[classes['MapMenuButton'], disabled ? classes['is-hidden'] : ''].join(' ')}>
        <Button
          disabled={disabled}
          icon={icon}
          clicked={clicked} />
      </div>
    )
  }
}

export default MapMenuButton;
