import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface privateRouteProps {
	component: typeof Component,
	isAuthenticated: boolean,
  localeChanged: (lang: string) => void;
  locale: string;
	path: string,
	exact?: boolean,
	redirectTo?: string,
}

const privateRoute = ({ component, isAuthenticated, locale, localeChanged, exact, redirectTo, ...rest }: privateRouteProps) => {
	if (!component) {
		throw Error("Component is undefined.");
	}

	const Component = component;

	const render = (props: RouteComponentProps<any>): React.ReactNode => {
		return (
			isAuthenticated ?
				<Component exact={exact} localeChanged={localeChanged} locale={locale} {...props} /> :
				<Redirect to={{ pathname: redirectTo ? redirectTo : '/' }} />
		);
	};

	return (<Route {...rest} render={render} />);
};

export default privateRoute;

