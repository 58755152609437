import React from 'react';
import classes from './styles.module.scss';
import doneIcon from '../../assets/icons/done.svg';

interface toastProps {
  children?: any;
}

const toast = ({ children }: toastProps) => {

	return (
	  <div className={classes.Toast}>
      <img src={doneIcon} alt={'done'} />
      <span>{children}</span>
	  </div>
  )
};

export default toast;
