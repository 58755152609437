import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ITask, ITaskStore } from '../../shared/interfaces';
import Button from '../Button';
import classes from './styles.module.scss';

interface IProps {
  taskStore: ITaskStore;
  taskClicked: (id: number) => void;
  trailerClicked: () => void;
}

interface IState {
  tasks: {
    firstRow: Array<ITask>;
    secondRow: Array<ITask>;
  };
  hoveredTask: number;
}

@inject('taskStore')
@observer
class TaskSelector extends Component<IProps, IState> {

  state = {
    tasks: {
      firstRow: this.props.taskStore.tasks.filter((task: ITask) => task.id > 0).slice(0, 5),
      secondRow: this.props.taskStore.tasks.filter((task: ITask) => task.id > 0).slice(5, 10),
    },
    hoveredTask: 0,
  };

  onMouseOut = () => {
    this.setState({
      hoveredTask: 0
    })
  };

  onMouseOver = (id: number) => {
    if(this.state.hoveredTask !== id){
      this.setState({
        hoveredTask: id
      })
    }
  };

  render() {
    const { taskClicked, taskStore, trailerClicked } = this.props;
    const { tasks, hoveredTask } = this.state;

    const currentHoveredTask = hoveredTask && taskStore.tasks.find((task: ITask) => task.id === hoveredTask);

    return (
      <div
        className={classes.TaskSelector}>
        <div className={classes['TaskSelector-header']}>
          <h2 className={classes['TaskSelector-title']}>
            <FormattedMessage id={'taskSelector.title'} />
          </h2>
          <h3 className={classes['TaskSelector-subtitle']}>
            <FormattedMessage id={'taskSelector.subtitle'} />
          </h3>
          <button
            className={classes['TaskSelector-showTrailer']}
            onClick={trailerClicked}>
            <FormattedHTMLMessage id={'button.showTrailer'} />
          </button>
        </div>
        <div className={classes['TaskSelector-content']}>
          <div className={classes['TaskSelector-firstRow']}>
            {
              tasks.firstRow.map(
                (task: ITask) =>
                  <div
                    key={task.id}
                    className={classes['TaskSelector-button']}>
                    <Button
                      mouseOut={this.onMouseOut}
                      mouseOver={() => this.onMouseOver(task.id)}
                      circle={true}
                      flat={true}
                      active={task.completed}
                      clicked={() => taskClicked(task.id)}>
                      {task.id.toString()}
                    </Button>
                    <span className={classes['TaskSelector-taskQuestion']}>{task.question}</span>
                    <span className={classes['TaskSelector-taskTitle']}>{task.title}</span>
                  </div>
              )
            }
          </div>
          {
            <div className={classes['TaskSelector-box']}>
              <div className={[classes['TaskSelector-description'], hoveredTask > 0 ? classes['is-visible'] : ''].join(' ')}>
                {

                  currentHoveredTask && currentHoveredTask.image ?
                    <Fragment>
                      <div className={classes['TaskSelector-image']}>
                        <img src={currentHoveredTask.image} alt={`marker-${currentHoveredTask.id}`} />
                      </div>
                      <div className={classes['TaskSelector-question']}>
                        <FormattedHTMLMessage id={'label.module'} />
                        <span className={classes['TaskSelector-id']}>{hoveredTask}:</span>
                        <span className={classes['TaskSelector-text']}>{currentHoveredTask.title}</span>
                      </div>
                    </Fragment> : null
                }
              </div>
            </div>
          }
          <div className={classes['TaskSelector-secondRow']}>
            {
              tasks.secondRow.map(
                (task: ITask) =>
                  <div
                    key={task.id}
                    className={classes['TaskSelector-button']}>
                    <Button
                      mouseOut={this.onMouseOut}
                      mouseOver={() => this.onMouseOver(task.id)}
                      circle={true}
                      flat={true}
                      active={task.completed}
                      clicked={() => taskClicked(task.id)}>
                      {task.id.toString()}
                    </Button>
                    <span className={classes['TaskSelector-taskQuestion']}>{task.question}</span>
                    <span className={classes['TaskSelector-taskTitle']}>{task.title}</span>
                  </div>
              )
            }
          </div>
        </div>
      </div >
    );
  };
}

export default TaskSelector;
