import React, { Component } from 'react';
/*
import {
  disableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';*/
import classes from './styles.module.scss';

interface IProps {
  children?: any;
}

class Backdrop extends Component<IProps> {
  targetRef = React.createRef<HTMLDivElement>();
  targetElement;

	componentDidMount() {
    if(this.targetRef){
      this.targetElement = this.targetRef.current;

      //disableBodyScroll(this.targetElement);
    }
		document.body.style.overflow = 'hidden';
	}

	componentWillUnmount() {
    //clearAllBodyScrollLocks();
		document.body.style.overflow = 'auto';
	}

	render () {

		const { children } = this.props;

		return (
      <div
        ref={this.targetRef}
        className={classes.Backdrop}>
        <div>
          {
            children
          }
        </div>
      </div>
		);
	}
}

export default Backdrop;
