import constants from "./constants";

export const getFluidHeightPercentage = (index: number, page: number) => {
  let fluidHeight: string = '0%';

  switch (page) {
    case 0: fluidHeight = constants.FLUIDS_HEIGHTS[index][0];
      break;
    case 1: fluidHeight = constants.FLUIDS_HEIGHTS[index][1];
      break;
    case 2: fluidHeight = constants.FLUIDS_HEIGHTS[index][2];
      break;
    case 3: fluidHeight = constants.FLUIDS_HEIGHTS[index][3];
      break;
    default: fluidHeight = constants.FLUIDS_HEIGHTS[index][0];
  }

  return fluidHeight;
};

export const getViewPortSize = () => {
  if (typeof window !== 'undefined') {
    return {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }

  return null;
};
