import { observable, action, computed, runInAction } from 'mobx';
import { ITask, ITaskStore } from '../shared/interfaces';
import { TASKS } from '../shared/data';

const isTesting = process.env.REACT_APP_TEST;

type IUSer = {
  id: string,
  displayName: string
}

class TaskStore implements ITaskStore {
  @observable tasks: Array<ITask> = TASKS;
  @observable user: IUSer;

  constructor(user) {
    this.user = user;
  }

  @action
  async fetchTask() {
    if (!this.user) return;
    const url = `${window.BACKEND_ENDPOINT}/getUserData?userID=${this.user.id}`;

    await fetch(url, { headers: { 'Content-Type': 'application/json' } })
      .then(res => res.json())
      .then(res => {
        if (res.data) {
          for (const taskID of res.data) {
            runInAction(() =>
              this.setTaskCompleted(taskID)
            )
          }
        }
      })
  }

  @action
  addTask = (task: ITask) => {
    this.tasks.push(task);
  };

  @action
  setTaskCompleted = async (taskId: number) => {
    const task = this.tasks.find((task: ITask) => task.id === taskId);
    if (task) {
      task.completed = true;
      const index = this.tasks.indexOf(task);
      this.tasks[index] = task;
    }
  };

  @action
  saveUserProgress = async () => {
    // For testing
    if (isTesting === 'true') {
      return;
    }

    const res = this.tasks
      .filter(task => task.completed)
      .map(task => task.id);

    const url = `${window.BACKEND_ENDPOINT}/setUserData`;
    await fetch(
      url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          userID: this.user.id,
          data: res
        })
      });

  };

  @action
  resetData = async () => {
    const url = `${window.BACKEND_ENDPOINT}/setUserData`;
    await fetch(
      url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          userID: this.user,
          data: []
        })
      });
  };

  @action
  resetTaskStatus = () => {
    this.tasks = this.tasks.map((task: ITask) => { return { ...task, completed: false } });
  };

  @action
  registerEvent = async (type: string, data?) => {
    await fetch(`${window.BACKEND_ENDPOINT}/saveEvent`, {
      method: 'PUT',
      body: JSON.stringify({
        userID: this.user.id,
        userName: this.user.displayName,
        eventType: type,
        data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  };

  @computed
  get tasksCount() {
    return this.tasks.length;
  }

  @computed
  get tasksCompleted() {
    return this.tasks.filter((task: ITask) => task.completed).length;
  }
}

export default TaskStore;
