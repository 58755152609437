import React, { Component, Fragment } from 'react';
import { AuthGate } from '../Auth';
import Button from '../Button';
import { FormattedHTMLMessage } from 'react-intl';


export default class AdminButton extends Component {

  render() {
    return (
      <AuthGate>
        {({ user }) => (
          <Fragment>
            {
              user && user.isAdmin
                ? <a
                  href={`${window.BACKEND_ENDPOINT}/dataset.xlsx`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button><FormattedHTMLMessage id="button.admin" /></Button>
                </a>
                : null
            }
          </Fragment>
        )}
      </AuthGate>
    )
  }
}