import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IMarkerPositions, ITask } from '../interfaces';

// Markers no-base
import marker1 from '../../assets/images/markers/1_no.png';
import marker2 from '../../assets/images/markers/2_no.png';
import marker3 from '../../assets/images/markers/3_no.png';
import marker4 from '../../assets/images/markers/4_no.png';
import marker5 from '../../assets/images/markers/5_no.png';
import marker6 from '../../assets/images/markers/6_no.png';
import marker7 from '../../assets/images/markers/7_no.png';
import marker8 from '../../assets/images/markers/8_no.png';
import marker9 from '../../assets/images/markers/9_no.png';
import marker10 from '../../assets/images/markers/10_no.png';

// Markers with base
import markerWithBase1 from '../../assets/images/markers/1_con.png';
import markerWithBase2 from '../../assets/images/markers/2_con.png';
import markerWithBase3 from '../../assets/images/markers/3_con.png';
import markerWithBase4 from '../../assets/images/markers/4_con.png';
import markerWithBase5 from '../../assets/images/markers/5_con.png';
import markerWithBase6 from '../../assets/images/markers/6_con.png';
import markerWithBase7 from '../../assets/images/markers/7_con.png';
import markerWithBase8 from '../../assets/images/markers/8_con.png';
import markerWithBase9 from '../../assets/images/markers/9_con.png';
import markerWithBase10 from '../../assets/images/markers/10_con.png';

// Markers green with base
import markerGreen1 from '../../assets/images/markers/1_no_verde.png';
import markerGreen2 from '../../assets/images/markers/2_no_verde.png';
import markerGreen3 from '../../assets/images/markers/3_no_verde.png';
import markerGreen4 from '../../assets/images/markers/4_no_verde.png';
import markerGreen5 from '../../assets/images/markers/5_no_verde.png';
import markerGreen6 from '../../assets/images/markers/6_no_verde.png';
import markerGreen7 from '../../assets/images/markers/7_no_verde.png';
import markerGreen8 from '../../assets/images/markers/8_no_verde.png';
import markerGreen9 from '../../assets/images/markers/9_no_verde.png';
import markerGreen10 from '../../assets/images/markers/10_no_verde.png';

// Objects
import bussolaImage from '../../assets/images/objects/bussola.gif';
import cappelloImage from '../../assets/images/objects/cappello.gif';
import fogliImage from '../../assets/images/objects/fogli.gif';
import lampadinaImage from '../../assets/images/objects/lampadina.gif';
import lenteImage from '../../assets/images/objects/lente.gif';
import libriImage from '../../assets/images/objects/libri.gif';
import lionImage from '../../assets/images/objects/lion.gif';
import matitoneImage from '../../assets/images/objects/matitone.gif';
import pergamenaImage from '../../assets/images/objects/pergamena.gif';
import quadernoImage from '../../assets/images/objects/quaderno.gif';

// Posters
// EN
import videoPlaceholderEnTrailer from '../../assets/images/posters/en/trailer_placeholder_en.png';
import videoPlaceholderEn0 from '../../assets/images/posters/en/0_placeholder_en.png';
import videoPlaceholderEn1 from '../../assets/images/posters/en/1_placeholder_en.png';
import videoPlaceholderEn2 from '../../assets/images/posters/en/2_placeholder_en.png';
import videoPlaceholderEn3 from '../../assets/images/posters/en/3_placeholder_en.png';
import videoPlaceholderEn4 from '../../assets/images/posters/en/4_placeholder_en.png';
import videoPlaceholderEn6 from '../../assets/images/posters/en/6_placeholder_en.png';
import videoPlaceholderEn61 from '../../assets/images/posters/en/6_placeholder_en_1.png';
import videoPlaceholderEn9 from '../../assets/images/posters/en/9_placeholder_en.png';
import videoPlaceholderEn10 from '../../assets/images/posters/en/10_placeholder_en.png';

// IT
import videoPlaceholderItTrailer from '../../assets/images/posters/it/trailer_placeholder_it.png';
import videoPlaceholderIt0 from '../../assets/images/posters/it/0_placeholder_it.png';
import videoPlaceholderIt1 from '../../assets/images/posters/it/1_placeholder_it.png';
import videoPlaceholderIt2 from '../../assets/images/posters/it/2_placeholder_it.png';
import videoPlaceholderIt3 from '../../assets/images/posters/it/3_placeholder_it.png';
import videoPlaceholderIt4 from '../../assets/images/posters/it/4_placeholder_it.png';
import videoPlaceholderIt6 from '../../assets/images/posters/it/6_placeholder_it.png';
import videoPlaceholderIt61 from '../../assets/images/posters/it/6_placeholder_it_1.png';
import videoPlaceholderIt9 from '../../assets/images/posters/it/9_placeholder_it.png';
import videoPlaceholderIt10 from '../../assets/images/posters/it/10_placeholder_it.png';

export const TRAILER: ITask = {
  id: 0,
  title: <FormattedMessage id={'task.zero.title'} />,
  question: <FormattedMessage id={'task.zero.question'} />,
  marker: null,
  completed: false,
  image: null,
  content: {
    en: [
      {
        type: 'video',
        poster: videoPlaceholderEnTrailer,
        url: 'https://vimeo.com/351222433/1c3ddba8a1'
      },
    ],
    it: [
      {
        type: 'video',
        poster: videoPlaceholderItTrailer,
        url: 'https://vimeo.com/351221985/584e9df346'
      },
    ]
  }
};

export const TASKS: Array<ITask> = [
  {
    id: 0,
    title: <FormattedMessage id={'task.zero.title'} />,
    question: <FormattedMessage id={'task.zero.question'} />,
    marker: null,
    completed: false,
    image: null,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn0,
          url: 'https://vimeo.com/351202218/a124af2de2'
        },
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt0,
          url: 'https://vimeo.com/343697631/da71ed912d'
        },
      ]
    }
  },
  {
    id: 1,
    title: <FormattedMessage id={'task.one.title'} />,
    question: <FormattedMessage id={'task.one.question'} />,
    marker: markerWithBase1,
    completed: false,
    image: lionImage,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn1,
          url: 'https://vimeo.com/351194558/1296ee72fd',
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.one.documents.title'} />,
          items: [
            {
              title: 'CASE STUDIES',
              subtitle: 'Distinctive features',
              url: process.env.PUBLIC_URL + '/docs/en/01/01_case_studies.pdf'
            },
            {
              title: 'LITERATURE REVIEW AND ANALYSIS',
              subtitle: 'Distinctive features',
              url: process.env.PUBLIC_URL + '/docs/en/01/01_literature_review_and_analysis.pdf'
            },
            {
              title: 'APPLICATIONS OF MODELS AND TOOLS',
              subtitle: 'Distinctive features',
              url: process.env.PUBLIC_URL + '/docs/en/01/01_applications_of_models_and_tools.pdf'
            },
            {
              title: 'THEORETICAL AND COMPUTATIONAL MODELS',
              subtitle: 'Distinctive features',
              url: process.env.PUBLIC_URL + '/docs/en/01/01_theoretical_and_computational_models.pdf'
            },
            {
              title: 'APPLIED STATISTICAL',
              subtitle: 'Distinctive features',
              url: process.env.PUBLIC_URL + '/docs/en/01/01_applied_statistical.pdf'
            },
          ]
        }
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt1,
          url: 'https://vimeo.com/343697685/8649a5ac46',
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.one.documents.title'} />,
          items: [
            {
              title: 'ANALISI DI CASI AZIENDALI',
              subtitle: 'Principali caratteristiche',
              url: process.env.PUBLIC_URL + '/docs/it/01/01_analisi_di_casi_aziendali.pdf'
            },
            {
              title: 'ANALISI DELLA LETTERATURA',
              subtitle: 'Principali caratteristiche',
              url: process.env.PUBLIC_URL + '/docs/it/01/01_analisi_della_letteratura.pdf'
            },
            {
              title: 'APPLICAZIONI DI MODELLI E STRUMENTI',
              subtitle: 'Principali caratteristiche',
              url: process.env.PUBLIC_URL + '/docs/it/01/01_applicazioni_di_modelli_e_strumenti.pdf'
            },
            {
              title: 'MODELLI TEORICI E COMPUTAZIONALI',
              subtitle: 'Principali caratteristiche',
              url: process.env.PUBLIC_URL + '/docs/it/01/01_modelli_teorici_e_computazionali.pdf'
            },
            {
              title: 'TESI STATISTICHE APPLICATE',
              subtitle: 'Principali caratteristiche',
              url: process.env.PUBLIC_URL + '/docs/it/01/01_tesi_statistiche_applicate.pdf'
            },
          ]
        }
      ]
    },
  },
  {
    id: 2,
    title: <FormattedMessage id={'task.two.title'} />,
    question: <FormattedMessage id={'task.two.question'} />,
    marker: markerWithBase2,
    completed: false,
    image: quadernoImage,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn2,
          url: 'https://vimeo.com/351205634/aa419135fd'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.two.documents.title'} />,
          items: [
            {
              title: 'CASE STUDIES',
              subtitle: 'Evaluation',
              url: process.env.PUBLIC_URL + '/docs/en/02/02_case_studies.pdf'
            },
            {
              title: 'LITERATURE REVIEW AND ANALYSIS',
              subtitle: 'Evaluation',
              url: process.env.PUBLIC_URL + '/docs/en/02/02_literature_review_and_analysis.pdf'
            },
            {
              title: 'APPLICATIONS OF MODELS AND TOOLS',
              subtitle: 'Evaluation',
              url: process.env.PUBLIC_URL + '/docs/en/02/02_applications_of_models_and_tools.pdf'
            },
            {
              title: 'THEORETICAL AND COMPUTATIONAL MODELS',
              subtitle: 'Evaluation',
              url: process.env.PUBLIC_URL + '/docs/en/02/02_theoretical_and_computational_models.pdf'
            },
            {
              title: 'APPLIED STATISTICAL',
              subtitle: 'Evaluation',
              url: process.env.PUBLIC_URL + '/docs/en/02/02_applied_statistical.pdf'
            },
          ]
        }
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt2,
          url: 'https://vimeo.com/343697757/c6f49c068f'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.two.documents.title'} />,
          items: [
            {
              title: 'ANALISI DI CASI AZIENDALI',
              subtitle: 'Criteri di valutazione',
              url: process.env.PUBLIC_URL + '/docs/it/02/02_analisi_di_casi_aziendali.pdf'
            },
            {
              title: 'ANALISI DELLA LETTERATURA',
              subtitle: 'Criteri di valutazione',
              url: process.env.PUBLIC_URL + '/docs/it/02/02_analisi_della_letteratura.pdf'
            },
            {
              title: 'APPLICAZIONI DI MODELLI E STRUMENTI',
              subtitle: 'Criteri di valutazione',
              url: process.env.PUBLIC_URL + '/docs/it/02/02_applicazioni_di_modelli_e_strumenti.pdf'
            },
            {
              title: 'MODELLI TEORICI E COMPUTAZIONALI',
              subtitle: 'Criteri di valutazione',
              url: process.env.PUBLIC_URL + '/docs/it/02/02_modelli_teorici_e_computazionali.pdf'
            },
            {
              title: 'TESI STATISTICHE APPLICATE',
              subtitle: 'Criteri di valutazione',
              url: process.env.PUBLIC_URL + '/docs/it/02/02_tesi_statistiche_applicate.pdf'
            },
          ]
        }
      ]
    }
  },
  {
    id: 3,
    title: <FormattedMessage id={'task.three.title'} />,
    question: <FormattedMessage id={'task.three.question'} />,
    marker: markerWithBase3,
    completed: false,
    image: lampadinaImage,
    content: {
      en: [
        {
          type: 'text',
          content: '<p>The idea of the Final Work can come about in different ways.</p>\n' +
            '<p>What’s important is that there is something personal. One of the main evaluation criteria of your Final Work is the level of autonomy in carrying out all the phases, including the identification of the initial idea.</p>\n' +
            '<p>You can get inspired by something you have studied for one of your exams. Remember that you can carry out your Final Work even regarding a subject that is not included in your curriculum.</p>\n' +
            '<p>You can maybe come up with the idea through a book or article read. It can come to you when listening to a speaker in class or at one of the many seminars and conferences that Bocconi University hosts.</p>\n' +
            '<p>Or maybe just by observing the world around you or discussing with colleagues, friends, or relatives.</p>\n' +
            '<p>You are probably wondering: what’s the Tutor’s job in identifying the idea of the Final Work? There are, at least, two approaches, depending on the preferences of the Tutor and the characteristics of the subject.</p>\n' +
            '<p>In many cases, the Tutor would rather you propose the idea, especially when the topic you have chosen has been studied in your degree course (for example, the management of credit institutions in a finance course, or the company strategy in a management one). In these cases, the role of the Tutor will be to help you clearly define the idea you have picked on your own.</p>\n' +
            '<p>In other cases, the Tutor might want help you identify the idea, especially when the topic you have chosen requires a knowledge you have only partially developed during your degree course (for example, applied mathematics in the case of a management course, or criminal law in the case of a finance course).</p>\n' +
            '<p>Anyway, always show up at the first meeting with your Tutor with a carefully developed project draft.</p>\n' +
            '<p>Two short videos are going to help you better understand these two alternative paths.</p>\n'
        },
        {
          type: 'video',
          poster: videoPlaceholderEn3,
          url: 'https://vimeo.com/351210346/0ac1a2de5d'
        },
        {
          type: 'text',
          content: `<p>Once you have defined the idea for your Final Paper with your Tutor, you can start the process that will lead you to your Graduation. The main steps are the following:</p>
<ul style="list-style-type:decimal;">
<li>Access the “Graduation Application procedure” available in the yoU@B Student's Diary under the ”Graduation” menu after the enrolment in the third year.</li>
<li><strong>Enter the title</strong> of your Final Paper and select the name of your Tutor: the confirmed title will be immediately available to the Tutor that will sign off on it.</li>
<li>Once the Tutor has approved your title, you will receive a notification in the yoU@B Student's. Now <strong>carry out the graduation reservation</strong>, so that you won’t forget!<br>
Bear in mind that the registration to the graduation session is activated only on specific dates that are reported in the <a href="https://www.unibocconi.it/wps/wcm/connect/Bocconi/SitoPubblico_IT/Albero+di+navigazione/Info/Studenti+iscritti/Orari%2C+Aule+e+Calendari/Appelli+di+Laurea+e+Prove+Finali/Corsi+di+laurea+triennali/" title="Go to sessions calendar" target="_blank">sessions calendar</a>, check it out to get an idea of the timing.<br>
Please note: if you think that the title is not the definitive one when registering, don’t worry: you will be able to edit it through the “Change title” function until its final approval or when you upload the final version of your Final Paper;</li>
<li><strong>Insert the abstract and upload the final version</strong> of your Final paper through the “Final paper title information completion”.</li> 
<li><strong>Request the approval</strong> through the appropriate function that will be active only if you have confirmed the information added in the “Final paper title information completion” function.</li>
</ul><br>
<p>The Graduation Application procedure is now complete: you just need to wait for the approval by your Tutor, which will be notified in the yoU@B Student's Diary</p>
`
        }
      ],
      it: [
        {
          type: 'text',
          content: '<p>L’idea del Lavoro Finale può nascere in tanti modi diversi.</p>\n' +
            '<p>L’importante è che ci sia almeno qualcosa di tuo, di personale nell’idea. Uno dei principali criteri di valutazione del tuo lavoro finale sarà infatti il grado di autonomia nello svolgimento delle singole fasi, compresa l’individuazione dell’idea iniziale.</p>\n' +
            '<p>L’idea iniziale può nascere da un argomento studiato per uno degli esami che hai sostenuto.</p>\n' +
            '<p>Ricordati però che puoi svolgere il lavoro finale anche nell’ambito di un insegnamento che non hai nel tuo piano studi.</p>\n' +
            '<p>Oppure, l’idea può nascere dalla lettura di un libro o di un articolo. Può venirti mentre partecipi a una testimonianza in aula, o a uno dei tanti seminari, convegni e incontri che l’Università Bocconi organizza.</p>\n' +
            '<p>Ancora, può nascere anche semplicemente osservando la realtà che ti circonda o discutendo con colleghi, amici e parenti.</p>\n' +
            '<p>Forse ti starai chiedendo: qual è il ruolo del docente tutor nell’individuazione dell’idea di lavoro finale? Ci sono almeno due approcci, a seconda delle preferenze del singolo docente e delle caratteristiche della disciplina.</p>\n' +
            '<p>In molti casi, il docente tutor preferisce che sia tu a proporre l’idea di tesi, soprattutto quando l’argomento che hai scelto è stato studiato a fondo nel tuo corso di laurea (ad esempio, la gestione degli istituti di credito in un corso di finanza, o la strategia aziendale in un corso di management).</p>\n' +
            '<p>In questi casi, il ruolo del docente tutor sarà quello di aiutarti a rifinire e precisare l’idea che hai individuato autonomamente.</p>\n' +
            '<p>In altri casi, il docente tutor preferisce aiutarti nell’individuazione dell’idea, soprattutto quando l’argomento che hai individuato richiede conoscenze che hai sviluppato solo in parte nel tuo corso di laurea (ad esempio: matematica applicata, nel caso di un corso in management, o diritto penale nel caso di un corso di finanza).</p>\n' +
            '<p>In ogni caso, presentati sempre al primo incontro con il tuo possibile docente tutor con una bozza di progetto di lavoro finale sviluppata con molta cura.</p>\n' +
            '<p>Due brevi video ti aiuteranno a capire meglio queste due strade alternative.</p>'
        },
        {
          type: 'video',
          poster: videoPlaceholderIt3,
          url: 'https://vimeo.com/351210376/9b44e6467c'
        },
        {
          type: 'text',
          //title: 'Perchè iniziare dalla biblioteca?',
          content: `<p>Una volta definita e concordata l’idea per il tuo Lavoro Finale con il docente tutor, puoi avviare il processo di Conseguimento del Titolo. I passi principali sono i seguenti:</p>
<ul style="list-style-type:decimal;">
<li>Accedi alla procedura di “Conseguimento titolo” disponibile in Agenda You@B sotto il menù “Laurea” successivamente all’iscrizione al terzo anno di corso.</li>
<li>Inserisci il titolo del Lavoro Finale e seleziona il nominativo del docente tutor: il titolo confermato sarà subito reso disponibile al docente che potrà rilasciare la sua approvazione.</li>
<li>Quando il docente tutor avrà rilasciato l’approvazione del titolo riceverai una notifica in Agenda You@B. A questo punto, prenotati all’appello di laurea eviterai così di dimenticartene. Tieni presente che la funzione di prenotazione all’appello di laurea si attiva in date specifiche che sono riportate nel <a href="https://www.unibocconi.it/wps/wcm/connect/Bocconi/SitoPubblico_IT/Albero+di+navigazione/info/Studenti+iscritti/Orari%2C+Aule+e+Calendari/Appelli+di+Laurea+e+Prove+Finali/" target="_blank" title="Vai al calendario delle sessioni">calendario delle sessioni</a>, consultalo per farti un’idea delle tempistiche.<br>
NB: se temi che, al momento dell’inserimento, il titolo non sia ancora definitivo, non temere, potrai comunque modificarlo fino a che non avrai richiesto il benestare attraverso la funzione “Modifica titolo” oppure quando dovrai caricare il file definitivo del Lavoro Finale.</li>
<li>Inserisci l’abstract e carica il file definitivo del Lavoro Finale attraverso la funzione ‘Completamento informazioni titolo lavoro finale’;</li>
<li>Richiedi il benestare attraverso l’apposita funzionalità che si attiva solo se hai correttamente confermato i dati inseriti in ‘Completamento informazioni titolo lavoro finale’.</li>
<li>Il processo di Conseguimento titolo è completato: non ti resta che attendere il rilascio del benestare da parte del docente tutor che ti verrà notificato in Agenda.</li>
</ul>
`
        }
      ]
    }
  },
  {
    id: 4,
    title: <FormattedMessage id={'task.four.title'} />,
    question: <FormattedMessage id={'task.four.question'} />,
    marker: markerWithBase4,
    completed: false,
    image: bussolaImage,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn4,
          url: 'https://vimeo.com/351196771/b0ecc3026d'
        },
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt4,
          url: 'https://vimeo.com/343699963/f87cfdb26f'
        },
      ]
    }
  },
  {
    id: 5,
    title: <FormattedMessage id={'task.five.title'} />,
    question: <FormattedMessage id={'task.five.question'} />,
    marker: markerWithBase5,
    completed: false,
    image: matitoneImage,
    content: {
      en: [
        {
          type: 'text',
          content: `<p>Developing the initial idea is one of the most critical phases in the elaboration of your Final Work. To advance your initial idea, you must create a <strong>Final Work Project</strong> and submit it to your Tutor for discussion, improvements, and final approval. The Final Work Project is a short (1-2 pages) document illustrating the essence of what you intend to accomplish. You should not consider it as a mere formality. Writing the Final Work Project will allow you to clarify and refine your ideas and to take an essential step towards starting to write the Final Work. The Final Work Project is also a requirement to start the relationship with your Tutor. You must go to the first meeting with your possible Tutor bringing a carefully crafted Final Work Project. To develop your Final Work Project you may follow a logical structure that touches upon 7 key questions.</p>`
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.five.documents.title'} />,
          items: [
            {
              title: 'FINAL WORK PROPOSAL',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/en/05/05_template_FW_proposal.docx'
            },
          ]
        }
      ],
      it: [
        {
          type: 'text',
          content: `<p>Lo sviluppo dell’idea iniziale è una delle fasi più critiche del processo di realizzazione del tuo lavoro finale. Per poter sviluppare l’idea iniziale dovrai scrivere un <strong>Progetto di Lavoro Finale</strong> e presentarlo al tuo Docente Tutor per discussioni, modifiche e per l’approvazione finale. Il Progetto di Lavoro Finale è un breve documento di 1-2 pagine, che illustra gli elementi fondamentali di ciò che intendi realizzare. Non devi considerarlo come una semplice formalità. Scrivere il Progetto di Lavoro Finale ti consentirà di chiarire e rifinire le tue idee e di compiere un passo essenziale verso l’inizio della stesura del Lavoro Finale stesso. Il Progetto di Lavoro Finale è anche un requisito per avviare la relazione con il tuo Docente Tutor. Dovrai recarti al primo incontro con il tuo possibile Docente Tutor portando un Progetto di Lavoro Finale realizzato con molta cura e attenzione. Per realizzare il tuo Progetto di Lavoro Finale puoi seguire una sequenza logica che affronta 7 domande chiave.</p>`
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.five.documents.title'} />,
          items: [
            {
              title: 'PROGETTO DI LAVORO FINALE',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/it/05/05_template_progetto_LF.docx'
            },
          ]
        }
      ]
    }
  },
  {
    id: 6,
    title: <FormattedMessage id={'task.six.title'} />,
    question: <FormattedMessage id={'task.six.question'} />,
    marker: markerWithBase6,
    completed: false,
    image: libriImage,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn6,
          url: 'https://vimeo.com/351197262/c49ce2814d'
        },
        {
          type: 'text',
          content: `<p>Your Tutor might suggest many structured ways to collect empirical data, or you might become aware of them by reading the literature. For example, there are many tools to analyze data traffic on a company or a startup’s website, which might come in handy when drafting a business plan.</p>
<p>Your Tutor might suggest other ways to collect qualitative data, or you might become aware of them by reading the literature. For example, Focus Groups are largely used in marketing studies to collect qualitative data.</p>`
        },
        {
          type: 'text',
          //title: '10 buoni motivi per iniziare la tua ricerca dalla Biblioteca',
          content: `<p>The best place to kick off your research for the Final Work is Bocconi Library. Even in the early
stages of idea generation, rather than searching on Google or Google Scholar, you should consider
starting from the Library. There are 10 good reasons:</p>
<ul style="list-style-type:decimal;">
<li><span>Rich and diverse available <strong>collections</strong>, both on paper and online (the latter are available
\t24/7 even from home). In particular:</span>
<ul>
\t<li>+ 850.000 books</li>
\t<li>+ 100.000 ebooks</li>
\t<li>+ 28.000 journals and ejournals</li>
\t<li>+ 90 databases</li>
</ul>
<span>...and a lot more, including all graduate, undergraduate and PhD Bocconi theses.</span>
</li>
<li><strong>Full-text</strong> of almost all online resources available for download.</li>
<li><span><strong>High-quality data and information</strong> because they are:</span>
\t<ul>
\t<li>Authoritative (authors are experts in the subject matters)</li>
\t<li>Accurate (trustworthy and specific)</li>
\t<li>Updated (the most recent studies available on each topic)</li>
\t<li>Relevant (focused on Bocconi subject areas)</li>
</ul>
<li>Your <strong>search time is optimized</strong> because available resources are selected by experts. For instance, resources may have been selected by your instructors and are thus closely related to your studies. On the opposite, when searching through Google, you are supposed toperform the selection ... which is often not an easy task!</li>
<li>The <strong>Interlibrary Loan Service</strong> can search for you what you cannot find in the available
\tresources.</li>
<li><strong>Search instruments</strong> (e.g., discovery tool and databases) allowing you to quickly retrieve data and information, to perform statistical analyses and comparisons across firms, to save the outcomes of your search, and a lot more.</li>
<li><strong>RefWorks</strong>, an online tool to organize the outputs of your search and to automatically generate a List of References.</li>
<li><strong>Expert Libriarians</strong> who can help you in your search if you cannot make the best out of a database, or who can simply provide you advice and guidance.</li>
<li>Focused <strong>Workshops</strong> on how to use databases, cite references, and prepare a List of References.</li>
<li><strong>Dedicated study rooms</strong> to perform your research and to discuss with your colleagues. The Library study rooms are open until midnight before each graduation session.</li>
</ul>`
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.six.documents.title'} />,
          items: [
            {
              title: 'How to perform an effective search',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/en/06/06_effective_search.pdf'
            },
          ]
        },
        {
          type: 'video',
          poster: videoPlaceholderEn61,
          url: 'https://vimeo.com/351208497/dcddb45d95'
        },
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt6,
          url: 'https://vimeo.com/343700006/f646a9c3aa'
        },
        {
          type: 'text',
          content: 'Diverse modalità strutturate di raccolta dei dati empirici potranno esserti suggerite dal docente\n' +
            'tutor, oppure potrai venirne a conoscenza consultando la letteratura di riferimento. Ad esempio,\n' +
            'sono disponibili vari strumenti per l’analisi dei dati di traffico sul sito internet di un’azienda o di\n' +
            'una startup, che potrebbero essere utili nella stesura di un business plan.\n' +
            'Altre modalità di raccolta di dati qualitativi potranno esserti suggerite dal docente tutor, oppure\n' +
            'potrai venirne a conoscenza consultando la letteratura di riferimento. Ad esempio, i Focus Group\n' +
            'sono un esempio di modalità di raccolta di dati qualitativi molto usata negli studi di marketing.'
        },
        {
          type: 'text',
          title: '10 buoni motivi per iniziare la tua ricerca dalla Biblioteca',
          content: '<p>Quando devi iniziare la tua ricerca per il Lavoro Finale, soprattutto all’inizio quando non hai ancora\n' +
            'le idee chiare, prima di cercare su Google o Google Scholar, parti dalla Biblioteca. Ci sono 10\n' +
            'buoni motivi per farlo!</p>\n' +
            '<ul style="list-style-type:decimal;">\n' +
            '<li><span>Collezioni a tua disposizione molto ricche e di vario tipo, sia su carta che online (queste\n' +
            'ultime sono disponibili 24/7 anche da casa):</span>\n' +
            '<ul>\n' +
            '<li>+ 850.000 libri</li>\n' +
            '<li>+ 100.000 ebooks</li>\n' +
            '<li>+ 28.000 periodici e ejournals</li>\n' +
            '<li>+ 90 banche dati</li>\n' +
            '</ul>\n' +
            '<span>\n' +
            'E molto altro ancora, tra cui tutte le tesi di laurea e dottorato dell’Università Bocconi</span></li>\n' +
            '<li>Disponibilità del full-text scaricabile nel caso di quasi tutte le risorse online</li>\n' +
            '<li><span>Informazioni e dati di qualità, perché sono:</span>\n' +
            '<ul>\n' +
            '<li>Autorevoli (gli autori sono esperti della materia)</li>\n' +
            '<li>Accurati (affidabili e corretti)</li>\n' +
            '<li>Aggiornati (i più recenti studi nei vari settori disciplinari)</li>\n' +
            '<li>Rilevanti (pertinenti alle aree disciplinari dell’Università)</li>\n' +
            '</ul>\n' +
            '</li>\n' +
            '<li>Il tuo tempo di ricerca è ottimizzato, perché le risorse a tua disposizione sono selezionate\n' +
            'da esperti, ad esempio possono essere state segnalate dai tuoi docenti, e sono pertinenti\n' +
            'all’ambito accademico. Ricordati che quando cerchi su Google la selezione la devi invece\n' +
            'fare tu (e non sempre questo è facile)</li>\n' +
            '<li>Il Servizio Prestito Interbibliotecario cerca per te nelle biblioteche di tutto il mondo quello\n' +
            'che non riesci a trovare</li>\n' +
            '<li>Strumenti di ricerca (discovery tool e banche dati) che ti permettono di recuperare dati e\n' +
            'informazioni in maniera rapida e immediata, elaborare i dati, fare analisi statistiche o\n' +
            'confronti tra società, salvare quello che trovi, e molto altro</li>\n' +
            '<li>RefWorks, un tool online per raccogliere le fonti che trovi e creare automaticamente la\n' +
            'bibliografia</li>\n' +
            '<li>Bibliotecari che ti possono aiutare nelle tue ricerche, se non sai come utilizzare al meglio\n' +
            'una banca dati, o ai quali chiedere semplicemente un consiglio</li>\n' +
            '<li>Workshop specifici sulle banche dati e su come citare e preparare la bibliografia</li>\n' +
            '<li>Spazi a te dedicati per svolgere le tue ricerche e per confrontarti con i tuoi compagni.\n' +
            'Ricordati che prima di ogni sessione di laurea le sale di lettura sono aperte fino a mezzanotte</li>\n' +
            '</ul>'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.six.documents.title'} />,
          items: [
            {
              title: 'Come fare una ricerca efficace',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/it/06/06_ricerca_efficace.pdf'
            },
          ]
        },
        {
          type: 'video',
          poster: videoPlaceholderIt61,
          url: 'https://vimeo.com/351211577/ca68e64741'
        },
      ]
    }
  },
  {
    id: 7,
    title: <FormattedMessage id={'task.seven.title'} />,
    question: <FormattedMessage id={'task.seven.question'} />,
    marker: markerWithBase7,
    completed: false,
    image: lenteImage,
    content: {
      en: [
        {
          type: 'text',
          content: `<p>If your Final Work is empirical, an essential part of it is the collection, analysis, and presentation of empirical data. Depending on the field in to which your Final Work refers and on your specific research questions, empirical data may be of significantly different types: numbers (e.g., extracted from a database or collected through a questionnaire), words (e.g., collected through interviews or documents), and, more seldom, images and artifacts. Depending on the type of empirical data and on your research questions you will thus have to use different methods of analysis and presentation approaches.</p>`
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.seven.documents.title'} />,
          items: [
            {
              title: 'CASE STUDIES',
              subtitle: 'The empirical part',
              url: process.env.PUBLIC_URL + '/docs/en/07/07_case_studies.pdf'
            },
            {
              title: 'LITERATURE REVIEW AND ANALYSIS',
              subtitle: 'The empirical part',
              url: process.env.PUBLIC_URL + '/docs/en/07/07_literature_review_and_analysis.pdf'
            },
            {
              title: 'APPLICATIONS OF MODELS AND TOOLS',
              subtitle: 'The empirical part',
              url: process.env.PUBLIC_URL + '/docs/en/07/07_applications_of_models_and_tools.pdf'
            },
            {
              title: 'THEORETICAL AND COMPUTATIONAL MODELS',
              subtitle: 'The empirical part',
              url: process.env.PUBLIC_URL + '/docs/en/07/07_theoretical_and_computational_models.pdf'
            },
            {
              title: 'APPLIED STATISTICAL',
              subtitle: 'The empirical part',
              url: process.env.PUBLIC_URL + '/docs/en/07/07_applied_statistical.pdf'
            },
          ]
        }
      ],
      it: [
        {
          type: 'text',
          content: 'Se il tuo Lavoro Finale è di tipo empirico, una parte centrale riguarda la raccolta, l’analisi e la\n' +
            'presentazione dei dati empirici. A seconda della disciplina entro cui si colloca il tuo Lavoro Finale e\n' +
            'delle tue specifiche domande di ricerca, i dati empirici possono essere di tipi molto diversi tra loro:\n' +
            'numeri (ad esempio, estratti da un database o raccolti tramite questionari), parole (ad esempio,\n' +
            'raccolti tramite interviste o documenti), e più raramente immagini e oggetti. A seconda del tipo di\n' +
            'dati empirici raccolti e delle domande di ricerca, dovrai quindi utilizzare un metodo di analisi e di\n' +
            'presentazione dei risultati differente.'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.seven.documents.title'} />,
          items: [
            {
              title: 'ANALISI DI CASI AZIENDALI',
              subtitle: 'La parte empirica',
              url: process.env.PUBLIC_URL + '/docs/it/07/07_analisi_di_casi_aziendali.pdf'
            },
            {
              title: 'ANALISI DELLA LETTERATURA',
              subtitle: 'La parte empirica',
              url: process.env.PUBLIC_URL + '/docs/it/07/07_analisi_della_letteratura.pdf'
            },
            {
              title: 'APPLICAZIONI DI MODELLI E STRUMENTI',
              subtitle: 'La parte empirica',
              url: process.env.PUBLIC_URL + '/docs/it/07/07_applicazioni_di_modelli_e_strumenti.pdf'
            },
            {
              title: 'MODELLI TEORICI E COMPUTAZIONALI',
              subtitle: 'La parte empirica',
              url: process.env.PUBLIC_URL + '/docs/it/07/07_modelli_teorici_e_computazionali.pdf'
            },
            {
              title: 'TESI STATISTICHE APPLICATE',
              subtitle: 'La parte empirica',
              url: process.env.PUBLIC_URL + '/docs/it/07/07_tesi_statistiche_applicate.pdf'
            },
          ]
        }
      ]
    }
  },
  {
    id: 8,
    title: <FormattedMessage id={'task.eight.title'} />,
    question: <FormattedMessage id={'task.eight.question'} />,
    marker: markerWithBase8,
    completed: false,
    image: fogliImage,
    content: {
      en: [
        {
          type: 'text',
          content: `<p>Each type of Final Work usually follows a slightly different structure (the “Table of Contents”). The structure of your Final Work will also depend on the disciplinary field, the specific topic you are addressing, the data and methods used, suggestions from your Tutor, templates you found in the existing literature, and your personal style. However, most Final Works tend to include the following chapters.</p>`
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.eight.documents.title.one'} />,
          items: [
            {
              title: 'La struttura del LF',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_FW_structure.pdf'
            },
          ]
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.eight.documents.title.two'} />,
          items: [
            {
              title: 'CASE STUDIES',
              subtitle: 'The structure',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_case_studies.pdf'
            },
            {
              title: 'LITERATURE REVIEW AND ANALYSIS',
              subtitle: 'The structure',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_literature_review_and_analysis.pdf'
            },
            {
              title: 'APPLICATIONS OF MODELS AND TOOLS',
              subtitle: 'The structure',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_applications_of_models_and_tools.pdf'
            },
            {
              title: 'THEORETICAL AND COMPUTATIONAL MODELS',
              subtitle: 'The structure',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_theoretical_and_computational_models.pdf'
            },
            {
              title: 'APPLIED STATISTICAL',
              subtitle: 'The structure',
              url: process.env.PUBLIC_URL + '/docs/en/08/08_applied_statistical.pdf'
            },
          ]
        }
      ],
      it: [
        {
          type: 'text',
          content: 'Ogni tipo di Lavoro Finale di solito segue una struttura (l’Indice dei capitoli) leggermente diversa.\n' +
            'La struttura del tuo Lavoro Finale dipenderà anche dalla disciplina, dall’argomento specifico che\n' +
            'stai affrontando, dai dati e dai metodi utilizzati, dai suggerimenti del tuo Tutor, dai modelli di\n' +
            'riferimento che hai trovato nella letteratura esistente e dal tuo stile personale. Tuttavia, la maggior parte delle opere finali tendono a includere i seguenti capitoli.'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.eight.documents.title.one'} />,
          items: [
            {
              title: 'La struttura del LF',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_struttura_LF.pdf'
            },
          ]
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.eight.documents.title.two'} />,
          items: [
            {
              title: 'ANALISI DI CASI AZIENDALI',
              subtitle: 'La struttura',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_analisi_di_casi_aziendali.pdf'
            },
            {
              title: 'ANALISI DELLA LETTERATURA',
              subtitle: 'La struttura',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_analisi_della_letteratura.pdf'
            },
            {
              title: 'APPLICAZIONI DI MODELLI E STRUMENTI',
              subtitle: 'La struttura',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_applicazioni_di_modelli_e_strumenti.pdf'
            },
            {
              title: 'MODELLI TEORICI E COMPUTAZIONALI',
              subtitle: 'La struttura',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_modelli_teorici_e_computazionali.pdf'
            },
            {
              title: 'TESI STATISTICHE APPLICATE',
              subtitle: 'La struttura',
              url: process.env.PUBLIC_URL + '/docs/it/08/08_tesi_statistiche_applicate.pdf'
            },
          ]
        }
      ]
    }
  },
  {
    id: 9,
    title: <FormattedMessage id={'task.nine.title'} />,
    question: <FormattedMessage id={'task.nine.question'} />,
    marker: markerWithBase9,
    completed: false,
    image: pergamenaImage,
    content: {
      en: [
        {
          type: 'text',
          title: '',
          content: '<p>The interaction with your Tutor is one of the most essential aspects when it comes to drafting your Final Work.<br>\n' +
            'All the steps of the drafting of the Final Work require a high degree of autonomy by the student. The value that will come from this interaction largely depends on you.</p> \n' +
            '<h5>The Tutor has three main roles:</h5>\n' +
            '<p>The <strong>first</strong> is to guide you through the setup of the project of your Final Work. The Tutor will help you identify and refine your initial idea based on your thoughts and interests. He/she will help you define the objectives and the research questions to make them interesting and feasible. Depending on the subject and personal preferences, some Tutors give broad autonomy in finding the topic, the objectives and the methods of the work. Others, instead, prefer to provide precise and detailed indications. Either way, your personal and autonomous contribution is essential. Show up at the first appointment with your potential Tutor with a project draft you have worked on thoroughly.</p>\n' +
            '<p>The <strong>second role</strong> is to support you in drafting the different parts of your Final Work. Every Tutor has a personal approach. In general, your Tutor will want to read one or more drafts of your work or single chapters to check the consistency with the initial project and the fairness of your analyses and writing. Ask your Tutor how to set the proofreading of your Final Work. Bear in mind, in particular, that you will have to hand in your drafts and the final version of your work in reasonable advance, to allow for a careful reading and further editing to incorporate comments and suggestions.</p>\n' +
            '<p>The <strong>third role</strong>, finally, is to propose the evaluation of your Final Work to the Graduation Committee. In proposing an evaluation, your Tutor will keep in mind the quality of your work, as well as the degree of autonomy and professionalism you have had when interacting with him/her. In written and oral communications and the delivery of the parts of the work, try to show autonomy, commitment and professionalism.</p>'
        },
        {
          type: 'video',
          poster: videoPlaceholderEn9,
          url: 'https://vimeo.com/351209956/1ef578cee7'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.nine.documents.title'} />,
          items: [
            {
              title: 'Mini-guide',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/en/09/09_behaviours_with_your_tutor.pdf'
            },
          ]
        },
      ],
      it: [
        {
          type: 'text',
          title: '',
          content: '<p>L’interazione con il docente tutor è uno degli aspetti più importanti nella realizzazione del tuo\n' +
            'lavoro finale.<br>\n' +
            'In tutte le fasi della stesura del lavoro finale è richiesto un elevato grado di autonomia da parte dello studente. Il valore che deriverà da questa interazione dipenderà quindi molto da te.</p>\n' +
            '<h5>Il docente tutor ha tre ruoli principali:</h5>\n' +
            '<p>Il <strong>primo ruolo</strong> è quello di guidarti nell’impostare il tuo progetto di lavoro finale. Il docente tutor potrà quindi aiutarti a individuare e a precisare l’idea iniziale, a partire dalle tue idee e dai tuoi interessi. Ti aiuterà a definire gli obiettivi e le domande di ricerca in modo che siano interessanti e fattibili. Ti guiderà poi nella scelta del metodo di ricerca e di lavoro più adatto all’argomento e agli obiettivi. A seconda della materia e delle preferenze personali, alcuni docenti tutor lasciano ampia autonomia nell’individuazione dell’argomento, degli obiettivi e del metodo di lavoro. Altri, invece, preferiscono dare indicazioni più puntuali e dettagliate. In entrambi i casi, è richiesto il tuo contributo autonomo e personale. Presentati quindi al primo incontro con il tuo possibile docente tutor con una bozza del progetto di lavoro finale sulla quale hai già riflettuto e lavorato seriamente e a fondo.</p>\n' +
            '<p>Il <strong>secondo ruolo</strong> è quello di supportarti nella stesura delle varie parti del lavoro finale. Anche qui ogni docente ha un approccio personale. In generale, comunque, il docente tutor vorrà leggere una o più bozze del tuo lavoro finale o di singoli capitoli, per verificare la coerenza con il progetto iniziale e la correttezza delle tue analisi e di quello che scriverai. Chiedi quindi all’inizio al tuo docente tutor come impostare la verifica delle bozze del tuo lavoro finale. Tieni presente, in particolare, che dovrai consegnare le bozze e la versione finale al docente tutor con un anticipo ragionevole, in modo da consentire una lettura attenta e l’inserimento poi delle tue modifiche per cogliere i commenti e i suggerimenti che riceverai.</p>\n' +
            '<p>Il <strong>terzo ruolo</strong>, infine, è quello di proporre alla commissione di laurea la valutazione del tuo lavoro finale. Nel proporre una valutazione il docente tutor terrà in considerazione non solo la qualità del lavoro, ma anche il grado di autonomia e di professionalità con cui hai impostato l’interazione con lui o con lei. Nelle comunicazioni verbali e scritte e nella consegna di parti del lavoro, cerca quindi di dimostrare sempre autonomia, serietà e professionalità.</p>'
        },
        {
          type: 'video',
          poster: videoPlaceholderIt9,
          url: 'https://vimeo.com/351209746/ec67ca33c5'
        },
        {
          type: 'documents',
          title: <FormattedMessage id={'task.nine.documents.title'} />,
          items: [
            {
              title: 'Mini-guida',
              subtitle: '',
              url: process.env.PUBLIC_URL + '/docs/it/09/09_relazione_tutor.pdf'
            },
          ]
        },
      ]
    }
  },
  {
    id: 10,
    title: <FormattedMessage id={'task.ten.title'} />,
    question: <FormattedMessage id={'task.ten.question'} />,
    marker: markerWithBase10,
    completed: false,
    image: cappelloImage,
    content: {
      en: [
        {
          type: 'video',
          poster: videoPlaceholderEn10,
          url: 'https://vimeo.com/351198037/dc26731964'
        },
        {
          type: 'text',
          title: 'Final Checklist!',
          content: '<ul><li>carefully check the content of Bocconi “Guides to the University” (<a href="https://www.unibocconi.eu/studentguide" target="_blank" title="Go to guides">www.unibocconi.eu/studentguide</a>) in relation to the features of the final document and of the online uploading procedure;</li>\n' +
            '<li>check your yoU@B Student\'s Diary where you can find a quick list of the main steps you have to take, and useful information to accurately finalize the Graduation application procedure;</li>\n' +
            '<li>always carefully check the deadlines you must meet to finalize the procedure.</li>\n' +
            '</ul><br>\n' +
            '<p>Good luck!</p>'
        },
      ],
      it: [
        {
          type: 'video',
          poster: videoPlaceholderIt10,
          url: 'https://vimeo.com/344060500/bd0dd7c031'
        },
        {
          type: 'text',
          title: 'Checklist finale!',
          content: '<ul>\n' +
            '<li>tieni ben presente il contenuto della “Guida all’Università” (<a href="https://www.unibocconi.it/tuttostudenti" target="_blank" title="Vai alla guida">www.unibocconi.it/tuttostudenti</a>) per quel che riguarda le specifiche del documento definitivo e i dettagli della procedura online di consegna;</li>\n' +
            '<li>dai un’occhiata alla tua Agenda YoU@B dove puoi trovare un veloce elenco dei passaggi cruciali e le informazioni utili per completare correttamente la procedura di Conseguimento titolo;</li>\n' +
            '<li>non perdere mai di vista le scadenze da rispettare.</li>\n' +
            '</ul><br>\n' +
            '<p>In bocca al lupo!</p>\n' +
            '<p>Buona fortuna!</p>\n'
        },
      ]
    }
  },
];

export const MARKER_POSITIONS: Array<IMarkerPositions> = [
  {
    minWidth: 320,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 800,
        y: 550,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 1000,
        y: 350,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 900,
        y: 200,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 650,
        y: 100,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 330,
        y: 250,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 320,
        y: 400,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 430,
        y: 460,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 600,
        y: 400,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 530,
        y: 200,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 780,
        y: 300,
        id: 10,
      },
    ]
  },
  {
    minWidth: 576,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 880,
        y: 780,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 1120,
        y: 520,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 1000,
        y: 350,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 700,
        y: 220,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 330,
        y: 450,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 300,
        y: 450,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 450,
        y: 670,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 630,
        y: 400,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 560,
        y: 370,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 850,
        y: 280,
        id: 10,
      },
    ]
  },
  {
    minWidth: 768,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 1050,
        y: 850,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 1300,
        y: 500,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 1200,
        y: 300,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 850,
        y: 170,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 400,
        y: 400,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 400,
        y: 620,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 550,
        y: 700,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 750,
        y: 600,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 670,
        y: 370,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 1030,
        y: 420,
        id: 10,
      },
    ]
  },
  {
    minWidth: 992,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 580,
        y: 400,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 720,
        y: 250,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 700,
        y: 200,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 500,
        y: 120,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 250,
        y: 200,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 300,
        y: 350,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 400,
        y: 400,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 450,
        y: 300,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 450,
        y: 200,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 620,
        y: 250,
        id: 10,
      },
    ]
  },
  {
    minWidth: 1280,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 750,
        y: 600,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 1000,
        y: 400,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 850,
        y: 270,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 650,
        y: 100,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 350,
        y: 300,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 300,
        y: 450,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 450,
        y: 500,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 600,
        y: 400,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 500,
        y: 250,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 750,
        y: 350,
        id: 10,
      },
    ]
  },
  {
    minWidth: 1440,
    markers: [
      {
        src: marker1,
        activeSrc: markerGreen1,
        x: 880,
        y: 630,
        id: 1,
      },
      {
        src: marker2,
        activeSrc: markerGreen2,
        x: 1100,
        y: 500,
        id: 2,
      },
      {
        src: marker3,
        activeSrc: markerGreen3,
        x: 1050,
        y: 300,
        id: 3,
      },
      {
        src: marker4,
        activeSrc: markerGreen4,
        x: 750,
        y: 200,
        id: 4,
      },
      {
        src: marker5,
        activeSrc: markerGreen5,
        x: 390,
        y: 360,
        id: 5,
      },
      {
        src: marker6,
        activeSrc: markerGreen6,
        x: 350,
        y: 500,
        id: 6,
      },
      {
        src: marker7,
        activeSrc: markerGreen7,
        x: 550,
        y: 550,
        id: 7,
      },
      {
        src: marker8,
        activeSrc: markerGreen8,
        x: 700,
        y: 450,
        id: 8,
      },
      {
        src: marker9,
        activeSrc: markerGreen9,
        x: 600,
        y: 250,
        id: 9,
      },
      {
        src: marker10,
        activeSrc: markerGreen10,
        x: 900,
        y: 350,
        id: 10,
      },
    ]
  },
];
