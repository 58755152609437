import React, { Component } from 'react';
import Backdrop from '../Backdrop';
import Button from '../Button';
import classes from './styles.module.scss';

import closeIcon from '../../assets/icons/close.svg';

interface IProps {
  title?: object | string;
  children: any;
  onModalCloseClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  visible: boolean;
}

interface IState {
  showBackdrop: boolean;
}

class Modal extends Component<IProps, IState> {
  private modal!: HTMLDivElement;

  state = {
    showBackdrop: false,
  };

  handleClickedOutsideModal = (e: any) => {
    if (this.modal && !this.modal.contains(e.target)) {
      this.props.onModalCloseClicked(e);
    }
  };

  componentWillMount(){
    document.addEventListener('click', this.handleClickedOutsideModal, false);
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickedOutsideModal, false);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if(this.props.visible !== prevProps.visible){
      setTimeout(() => {
        this.setState({
          showBackdrop: this.props.visible
        })
      }, 400)
    }
  }

  render() {
    const { showBackdrop } = this.state;
    const { children, onModalCloseClicked, visible } = this.props;

    return showBackdrop && (
      <Backdrop>
        <div>
          <div className={[classes.Modal, visible ? classes['is-visible'] : ''].join(' ')} ref={(node: HTMLDivElement) => { this.modal = node; }}>
            <div className={classes['Modal-close']}>
              <Button
                size={'small'}
                icon={closeIcon}
                clicked={onModalCloseClicked} />
            </div>
            <div className={classes["Modal-content"]}>
              {
                children
              }
            </div>
          </div>
        </div>
      </Backdrop>
    )
  }
}

export default Modal;
