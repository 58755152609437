import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
  id: number;
	clicked: (id: number) => void;
  disabled: boolean;
  image?: string;
}

class Marker extends Component<IProps> {
  render() {
    const { id, clicked, disabled, image } = this.props;

    return (
      <button
        className={classes.Marker}
        disabled={disabled}
        onClick={() => clicked(id)}>
        <img
          alt={`marker-${id}`}
          src={image} />
      </button>
    )
  }
}

export default Marker;
