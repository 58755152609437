import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as routes from '../../shared/routes';
import { History } from 'history';
import { inject, observer } from 'mobx-react';
import Button from '../Button';
import Logo from '../Logo';
import classes from './styles.module.scss';
import Confetti from 'react-confetti'

// Placeholder
import endBackground from '../../assets/images/end.jpg';

// Icon
import arrowStartIcon from '../../assets/icons/arrow_start.svg';
import { ITaskStore } from '../../shared/interfaces';

interface IProps {
  taskStore: ITaskStore;
  history: History;
}

@inject('taskStore')
@observer
export default class ThankYou extends Component<IProps> {

  restart = async () => {
    this.props.taskStore.resetTaskStatus();
    await this.props.taskStore.resetData();
    this.props.history.push(routes.WELCOME)
  };

  componentDidMount(): void {
    if (this.props.taskStore.tasksCompleted < 10) {
      this.props.history.push(routes.WELCOME);
    }
  }

  render() {

    return (
      <div className={classes.ThankYou}>
        <Confetti />
        <Logo />
        <div
          className={classes['ThankYou-wrapper']}>
          <div className={classes['ThankYou-restart']}>
            <h1 className={classes['ThankYou-title']}>
              <FormattedHTMLMessage id={'thankYou.text'} />
            </h1>
            <Button
              icon={arrowStartIcon}
              clicked={this.restart}>
              <FormattedMessage id={'button.restart'} />
            </Button>
          </div>
          <img className={classes['ThankYou-background']} src={endBackground} alt={'intro'} />
        </div>
      </div>
    );
  }
}
