export default {
  LOCAL_STORAGE: {
    KEYS: {
      USER_ID: 'ac_user_id',
      AUTH_TOKEN: 'ac_auth_token',
    }
  },
  FLUIDS_HEIGHTS: {
    0: ['0%', '0%', '0%', '0%'],
    1: ['0%', '99%', '99%', '99%'],
    2: ['0%', '49%', '99%', '99%'],
    3: ['0%', '33%', '66%', '99%'],
    4: ['0%', '25%', '50%', '70%', '99'],
    5: ['0%', '20%', '40%', '80%', '99'],
  },
  SLIDER_SETTINGS: {
    arrows: false,
    speed: 300,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    // Bug for fade in this package: do not use!
    //fade: true,
  }
}
