import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import constants from "../../shared/constants";
import { getFluidHeightPercentage } from "../../shared/helpers";
import { ITask, ITaskContent } from '../../shared/interfaces';
import Button from '../Button';
import DocsList from '../DocsList';
import classes from './styles.module.scss';
import Slider from "react-slick";
import { inject } from 'mobx-react';
import './styles.css';
import arrowUpIcon from '../../assets/icons/arrow_up.svg';
import arrowDownIcon from '../../assets/icons/arrow_down.svg';
import playIcon from '../../assets/icons/play.svg';
import pdfImage from '../../assets/images/pdf.png';
import TaskStore from '../../stores/task.store';

interface IProps {
  task: ITask;
  completeTaskClicked: (id: number) => void;
  locale: string;
  taskStore?: TaskStore;
}

interface IState {
  currentPage: number;
  playing: Array<boolean>;
}


@inject('taskStore')
class TaskComponent extends Component<IProps, IState> {
  private slider = createRef<any>();
  private videoPlayer = createRef<any>();

  state = {
    currentPage: 0,
    playing: [false, false, false, false],
  };

  setCurrentPage = (index) => {
    this.setState({
      currentPage: index,
      playing: [false, false, false, false]
    })
  };

  handleChangePage = (index: number) => {
    if (this.slider.current) {
      this.slider.current.slickGoTo(index);

      this.setCurrentPage(index);
    }
  };

  handleGoToDirection = (direction: string) => {
    if (this.slider.current) {
      direction === 'next' ? this.slider.current.slickNext() : this.slider.current.slickPrev();

      this.setCurrentPage(this.state.currentPage + (direction === 'next' ? 1 : -1));
    }
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevProps.task !== this.props.task) {
      this.handleChangePage(0);
    }
  }

  componentWillUnmount(): void {
    // Reset component
    this.handleChangePage(-1)
  }

  handlePlayingStoppingVideo = (index: number) => {
    let playing: Array<boolean> = [...this.state.playing];
    playing[index] = !playing[index];

    if (playing[index]) {

    }

    this.setState(
      {
        playing
      }
    );

    this.props.taskStore && this.props.taskStore.registerEvent('start-video');
  };

  render() {
    const { task: { id, title, marker, content }, completeTaskClicked, locale } = this.props;
    const { currentPage, playing } = this.state;

    return (
      <div
        className={classes.Task}>
        {
          marker &&
          <img
            className={classes['Task-marker']}
            src={marker}
            alt={`task-${id}`} />
        }
        <div className={classes['Task-header']}>
          <h2 className={classes['Task-title']}>{title}</h2>
        </div>
        <div className={classes['Task-container']}>
          {
            content[locale || 'it'].length > 1 &&
            <div className={[classes['Task-arrow'], classes['Task-arrow--up']].join(' ')}>
              <Button
                disabled={currentPage === 0}
                clicked={() => this.handleGoToDirection('previous')}
                icon={arrowUpIcon} />
            </div>
          }
          <Slider
            ref={this.slider}
            {...constants.SLIDER_SETTINGS}>
            {
              content[locale || 'it'].map((taskContent: ITaskContent, index: number) => {
                let tpl;

                switch (taskContent.type) {
                  case 'video': tpl =
                    <div key={index} className={classes['Task-content']}>
                      <ReactPlayer
                        ref={this.videoPlayer}
                        className={[classes['Task-player'], playing[index] ? classes['Task-player--over'] : ''].join(' ')}
                        url={taskContent.url}
                        width={'100%'}
                        height={'100%'}
                        allowFullScreen
                        config={{
                          vimeo: {
                            playerOptions: { controls: true }
                          },
                        }}
                        playing={playing[index]} />
                      <div className={[classes['Task-poster'], !playing[index] ? classes['is-visible'] : ''].join(' ')}>
                        <div
                          className={[classes['Task-background'], !playing[index] ? classes['is-visible'] : ''].join(' ')}
                          style={{ backgroundImage: `url(${taskContent.poster})` }} />
                        <div className={classes['Task-controls']}>
                          <Button
                            icon={playIcon}
                            size={'small'}
                            clicked={() => this.handlePlayingStoppingVideo(index)} />
                        </div>
                      </div>
                    </div>;
                    break;
                  case 'text': tpl =
                    <div key={index} className={classes['Task-content']}>
                      <div className={classes['Task-text']}>
                        {
                          taskContent.title &&
                          <h4>{taskContent.title}</h4>
                        }
                        {
                          taskContent.content &&
                          <div dangerouslySetInnerHTML={{ __html: taskContent.content }} />
                        }
                      </div>
                    </div>;
                    break;
                  case 'documents': tpl =
                    <div key={index} className={classes['Task-content']}>
                      <div className={classes['Task-documents']}>
                        <div className={[
                          classes['Task-docImage'],
                          taskContent.items && taskContent.items.length === 1 ? classes['Task-docImage--short'] : '',
                        ].join(' ')}>
                          <img src={pdfImage} alt={'pdf'} />
                        </div>
                        <DocsList
                          title={taskContent.title}
                          docs={taskContent.items}
                          onDocClick={(title: string) =>
                            this.props.taskStore &&
                            this.props.taskStore.registerEvent('open-doc', { document: title })
                          }
                        />
                      </div>
                    </div>;
                    break;
                  default: tpl = null;
                }

                return tpl;
              })
            }
          </Slider>
          <div className={[classes['Task-arrow'], classes['Task-arrow--down']].join(' ')}>
            <Button
              styles={{
                visibility: currentPage === content[locale || 'it'].length - 1 ? 'visible' : 'hidden',
                position: currentPage === content[locale || 'it'].length - 1 ? 'static' : 'absolute',
                opacity: currentPage === content[locale || 'it'].length - 1 ? 1 : 0,
              }}
              size={'small'}
              theme={'success'}
              clicked={() => completeTaskClicked(id)}>
              <FormattedMessage id={'button.complete'} />
            </Button>
            <Button
              styles={{
                visibility: currentPage === content[locale || 'it'].length - 1 ? 'hidden' : 'visible',
                position: currentPage === content[locale || 'it'].length - 1 ? 'absolute' : 'static',
                opacity: currentPage === content[locale || 'it'].length - 1 ? 0 : 1,
              }}
              clicked={() => this.handleGoToDirection('next')}
              icon={arrowDownIcon} />
          </div>
          {
            content[locale || 'it'].length > 1 &&
            <div className={classes['Task-pages']}>
              <div className={classes['Task-tower']}>
                <div className={classes['Task-progress']}>
                  <div
                    style={{ height: getFluidHeightPercentage(content[locale || 'it'].length - 1, currentPage) }}
                    className={classes['Task-fluid']} />
                </div>
                {

                  content[locale || 'it'].map((taskContent: ITaskContent, index: number) => {
                    return (
                      <button
                        key={index}
                        onClick={() => this.handleChangePage(index)}
                        className={[classes['Task-page'], currentPage >= index ? classes['is-active'] : ''].join(' ')}>{index + 1}</button>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  };
}

export default TaskComponent;
